<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  ></loading>

  <div class="page-wrapper">
    <div class="container-fluid position-relative">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3"><span>Modifier le package</span></li>
            </ol>
          </div>
        </div>
      </div>
      <div class="icon">
        <h1 @click="$router.go(-1)">
          <box-icon name="left-arrow-alt" animation="tada"></box-icon>
        </h1>
      </div>
    </div>
    <h1 v-if="libelle !== null">
      Nom du package :<input
        type="text"
        class="w-25"
        v-model="libelle.libelle"
        disabled
      />
    </h1>
    <h5
      v-if="libelle !== null && libelle.commentaire_rejet"
      class="alert alert-danger w-100 my-1"
      role="alert"
    >
      {{ libelle.commentaire_rejet }}
    </h5>
    <form @submit.prevent="modifyArticle">
      <div class="form-body" v-if="listPackages">
        <div class="card-body">
          <div class="row pt-3">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">Nom de l'article</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="libelle.libelle"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">Choisir le package</label>
                  <select
                    class="w-100 form-group types"
                    v-model="libelle.type.id"
                    id="type"
                  >
                    <option value="" disabled selected>Packages</option>
                    <option
                      v-for="type in list_type_packages"
                      :key="type.id"
                      :value="type.id"
                      :label="type.libelle"
                    >
                      {{ type.libelle }}
                    </option>
                  </select>
                </div>
              </div>
            
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">Prix d'achat par pièce (Fcfa)</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ex:12000 fcfa"
                    v-model="libelle.cout_acquisition"
                    pattern="^([0-9]*)$"
                  />
                </div>
              </div>
           
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label"
                    >Prix de vente par pièces (Fcfa)</label
                  >
                  <input
                    type="text"
                    pattern="^([0-9]*)$"
                    class="form-control form-control-danger"
                    placeholder="ex:3000 fcfa"
                    v-model="libelle.cout_vente"
                  />
                </div>
              </div>
              <div class="col-md-6" v-if="!this.libelle.franchise">
                <div class="form-group">
                  <label class="form-label">Délai d'ecoulement (Nbre de jours)</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="libelle.nb_jours"
                    pattern="^([0-9]*)$"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">Nombre de pièces</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ex:200"
                    v-model="libelle.nb_products"
                    pattern="^([0-9]*)$"
                  />
                </div>
              </div>
             
              <div class="col-md-6" v-if="!this.libelle.franchise">
                <div class="form-group">
                  <label class="form-label">Nombre de part</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ex:200"
                    v-model="libelle.tranche"
                    pattern="^([0-9]*)$"
                  />
                </div>
              </div>
              <div class="col-md-6">
               
                <div class="form-group">
                  <label class="form-label">Revenu</label>
                  <select
                    class="w-100 form-group types"
                    v-model="libelle.income_type"
                    id="type"
                    required
                  >
                    <option value="" disabled>-----Revenu-----</option>
                    <option
                      value="journalier"
                    >
                    Journalier
                    </option>
                    <option
                      value="mensuel"
                    >
                    Mensuel
                    </option>
                    <option
                      value="hebdomadaire"
                    >
                    Hebdomadaire
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                
                  <label class="form-label d-block">Image</label>
                 <div class="d-flex justify-content-between align-items-center">
                  <input type="file" ref="takePicture" @change="handleFileUpload">
                  <n-image
                  v-if="libelle.photo"
                  width="100"
                  :src="'https://backend.invest-ci.com/public'+ libelle.photo"
                  :alt="libelle.photo"
                />
                <span v-else>Pas de photo pour ce package</span>
                 </div>
                </div>
              </div>
              
             
            </div>
           
         
          </div>
          <!--/row-->

          
        </div>
        <div class="form-actions">
          <div class="card-body">
            <button type="submit" class="btn text-white mx-3 btn-primary">
              Modifier
            </button>
            <button @click="$router.go(-1)" type="button" class="btn btn-dark">
              Retour
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import axios from "axios";
import { lien } from "/src/assets/api.js";

import router from "../../router";
export default {
  name: "ModifierPackage",
  data() {
    return {
      listPackages: null,
      libelle: null,
      user: this.$store.state.user,
      publie: null,
      isLoading: false,
      list_type_packages:[],
      takePicturePackage:"",
    
    };
  },
  components: {
  
    Loading,
  },
  created() {

    this.get_Package();
    this.isLoading = true;
    //======== ceci sert a recupere les informations de l utilisateur//
    axios
      .get(lien + "/api/packages")
      .then((reponse) => {
        //console.log("ARTicle a modifier", reponse.data.data);
        this.listPackages = reponse.data.data;
        this.libelle = this.listPackages.find(
          (item) => item.id == this.$route.params.id
        );
       
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
      });

    //ceci sert a recupere les informations de l utilisateur ========//
    //console.log("USER", this.user.id);
  },
  methods: {
    get_Package() {
      axios
        .get(lien + "/api/types")
        .then((reponse) => {
          //console.log("POSTTYPEPACKAGE", reponse);
          this.list_type_packages = reponse.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleFileUpload(event) {
      this.takePicturePackage = event.target.files[0];
      // console.log(this.takePicturePackage);
    },
    modifyArticle() {
      let prixVente = this.libelle.cout_vente;
      let prixAchat = this.libelle.cout_acquisition;

      if (prixVente < prixAchat || prixVente == prixAchat) {
        Swal.fire({
          text: "Le prix de vente doit etre supérieur au prix d'achat",
          icon: "info",
          showConfirmButton: true,
        });
      }else if(this.libelle.nb_products % this.libelle.tranche == 0 || this.libelle.franchise == "1"){
        this.modify();
      }else{
        Swal.fire({
          text: "le nombre de lot choisi doit etre divisible par le nombre de produits.",
          icon: "info",
          showConfirmButton: true,
         
        });
      }
      
     
    },
    modify() {
      let data = new FormData();
      data.append("cout_acquisition", this.libelle.cout_acquisition)
      data.append("cout_vente", this.libelle.cout_vente)
      data.append("libelle" ,this.libelle.libelle)
      data.append(" nb_products" ,this.libelle.nb_products)
      data.append("nb_jours" ,this.libelle.nb_jours ? this.libelle.nb_jours:0)
      data.append("user_id" ,this.user.id)
      data.append("type_id" ,this.libelle.type.id)
      data.append("income_type",this.libelle.income_type)
      data.append("tranche" ,this.libelle.tranche)
      data.append("etat" ,"en cours de traitement")
      data.append("photo",this.takePicturePackage)
      data.append("id" ,this.$route.params.id)
      axios
        .post(lien + `/api/edit-article`,data)
        .then((reponse) => {
     
          if (reponse.data.status == "true" || reponse.data.status === true) {
            Swal.fire({
              text: "Package modifié",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            setTimeout(() => {
              router.go(-1);
            }, 1500);
          }
          if (reponse.data.status == "false") {
            Swal.fire({
              text: "Modification echouée",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          if (error) {
            Swal.fire({
              text: "Modification echouée",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
* {
  font-weight: bold;
}
.icon {
  position: absolute;
  left: 1em;
  top: 0;
}
.icon h1 {
  cursor: pointer;
}
.publie {
  min-height: 38px;
  border: none;
  border-radius: 0.25rem;
}
.btn-primary {
  background: rgb(231, 202, 15) !important;
  border: 3px solid black !important;
  font-weight: bold !important;
}
.w-25 {
  width: 10% !important;
}

.my_footer {
  position: relative;
  width: 100%;
  bottom: -8em;
  margin-left: 0 !important;
}
select {
  padding: 0.589em !important;

  border-radius: 0.25rem;
}
select:active {
  border: 1px solid rgb(134, 134, 134);
}
input,
select {
  border: 1px solid black !important;
}
.form-group {
  text-align: left !important;
}
</style>
